import config from 'config';
import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';

import RootStyles from './core/components/RootStyles';
import ErrorBoundary from './core/dashboard/ErrorBoundary';
import { setKiboCustomVariables } from './kibo/utils';
import AuthenticationPage from './site-picker/components/Auth/AuthenticationPage';
import SitePicker from './site-picker/components/SitePicker';

ReactGA.initialize(config.get('hostGATrackingID'));

const data = window.__DATA__;

window.history.replaceState(null, '', '/');

// ported from legacy base template
const initData = {
  mintedUrls: {
    backboneBaseUrl: `${config.get('mintedBaseUrl')}/backbone`,
    cart: `${config.get('mintedBaseUrl')}/cart`,
    'contact-us': `${config.get('mintedBaseUrl')}/contact-us`,
    feedback: `${config.get('mintedBaseUrl')}/feedback`,
    help: `${config.get('mintedBaseUrl')}/help`,
    logout: `${config.get('mintedBaseUrl')}/logout`,
    'my-account': `${config.get('mintedBaseUrl')}/my-account`,
    'my-saved-designs': `${config.get('mintedBaseUrl')}/my-saved-designs`,
    privacy: `${config.get('mintedBaseUrl')}/privacy`,
    terms: `${config.get('mintedBaseUrl')}/terms`,
  },
};

const navBarProps = {
  mintedUrls: initData.mintedUrls,
  userFirstName: '',
};

const kiboSKUMap = data.map((site) => {
  if (site.siteType === 'wedding') {
    return {
      name: 'weddingSiteProductCode',
      value: site.mintedProductSku,
    };
  }

  return {
    name: 'simplifiedSite',
    value: true,
  };
});

setKiboCustomVariables(kiboSKUMap, 'minted-website-picker');

if (data.length > 0) {
  render(
    (
      <ErrorBoundary>
        <RootStyles>
          <SitePicker
            navBarProps={navBarProps}
            sites={data}
          />
        </RootStyles>
      </ErrorBoundary>
    ),
    document.getElementById('app'),
  );
} else {
  render(
    (
      <ErrorBoundary>
        <RootStyles>
          <AuthenticationPage />
        </RootStyles>
      </ErrorBoundary>
    ),
    document.getElementById('app'),

  );
}
