import { css } from '@emotion/react';
import { theme, Alert } from '@minted/minted-components';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import {
  React, useState, useCallback,
} from 'react';

import SiteSection from './SiteSection';
import { SITE_TYPES } from '../../core/constants';
import SiteFooter from '../../core/dashboard/SiteFooter';
import NavigationContext from '../../navigation/dashboard/NavigationContext';
import SiteNavigation from '../../navigation/dashboard/SiteNavigation';

const sitePickerStyles = css`
  align-items: center;
  background-color: ${theme.colors.gray100};
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacing.x6};
  padding-left: ${theme.spacing.x16};
  padding-right: ${theme.spacing.x16};
`;

const sectionTypes = [
  'active',
  'archived',
  'deleted',
];

const SitePicker = ({
  navBarProps,
  sites,
}) => {
  const [
    errorMessage,
    setErrorMessage,
  ] = useState('');
  const orderedSites = partition(sites, (site) => site.siteType === SITE_TYPES.WEDDING).flat(1);

  const clearAlert = useCallback(() => {
    setErrorMessage('');
  }, []);

  return (
    <div>
      <NavigationContext.Provider value={navBarProps}>
        <SiteNavigation
          isMultiSiteDashboard={true}
        />
      </NavigationContext.Provider>

      <Alert
        onCloseClick={clearAlert}
        secondaryText={errorMessage}
        show={!!errorMessage}
        type={Alert.types.negative}
      />
      <div css={sitePickerStyles}>
        {
          sectionTypes.map((sectionType)=> (
            <SiteSection
              key={sectionType}
              sectionType={sectionType}
              setErrorMessage={setErrorMessage}
              sites={orderedSites}
            />
          ))
        }
      </div>
      <SiteFooter />

    </div>
  );
};

SitePicker.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SitePicker;
