import { css, Global } from '@emotion/react';
import {
  Button, Icon, styleUtils, theme,
} from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import NavigationContext from './NavigationContext';
import SimplifiedMobileNavigationHeader from './SimplifiedMobileNavigationHeader';
import ViewWeddingWebsite from './ViewWeddingWebsite';
import useOpen from '../../core/hooks/useOpen';

const {
  rem,
} = styleUtils;

const navigationLinkStyles = (
  css`
    ${theme.typeStyles.bodySmallStyles};
    ${theme.typeStyles.bodyBoldStyles};
    align-items: center;
    border-left: ${rem(3)} solid transparent;
    color: ${theme.colors.gray700};
    cursor: pointer;
    display: flex;
    height: ${rem(48)};
    padding-left: ${theme.spacing.x6};

    :hover {
      background: rgba(230, 230, 230, 0.25);
    }
  `
);

const mintedUrlLinkStyles = (
  css`
    ${theme.typeStyles.bodyExtraSmallStyles}
    align-items: center;
    border-left: ${rem(3)} solid transparent;
    color: ${theme.colors.gray700};
    cursor: pointer;
    display: flex;
    height: ${rem(32)};
    padding-left: ${theme.spacing.x6};
    text-decoration: none;
    :hover {
      background: rgba(102, 102, 102, 0.25);
    }
  `
);

const openNavContainerStyles = css`
  background: ${theme.colors.gray100};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9;
`;

const navigationLinkContainerStyles = css`
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationLogoutContainerStyles = css`
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationContainerOverflowScrollStyles = css`
  overflow-y: scroll;
`;

const propTypes = {
  isMultiSiteDashboard: PropTypes.bool,
};

const SimplifiedMobileNavigation = ({
  isMultiSiteDashboard,
}) => {
  const {
    mintedUrls,
  } = useContext(NavigationContext);

  const {
    isOpen,
    toggle,
  } = useOpen();

  return (
    <div
      css={
        isOpen
        && openNavContainerStyles
      }
    >
      {
        isOpen
        && (
          <Global
            styles={
              css`
                body {
                  overflow: hidden;
                }
              `
            }
          />
        )
      }

      <SimplifiedMobileNavigationHeader
        isMultiSiteDashboard={isMultiSiteDashboard}
        isOpen={isOpen}
        toggleMenu={toggle}
      />

      {
        isOpen
        && (
          <div
            css={navigationContainerOverflowScrollStyles}
          >
            {
              !isMultiSiteDashboard && (
                <div
                  css={
                    navigationLinkContainerStyles
                  }
                >
                  <ViewWeddingWebsite>
                    {
                      ({
                        navigateToWeddingWebsite,
                      }) => (
                        <div css={navigationLinkStyles}>
                          <Button
                            iconAlignment={Button.iconAlignments.left}
                            iconType={Icon.types['open-in-new']}
                            onClick={navigateToWeddingWebsite}
                            text="View Your Website"
                            type={Button.types.tertiary}
                          />
                        </div>
                      )
                    }
                  </ViewWeddingWebsite>
                </div>
              )
            }

            <div
              css={navigationLinkContainerStyles}
            >
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls['my-account']}
              >
                My Account
              </a>
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.help}
              >
                Website Help
              </a>
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.terms}
              >
                Terms & Conditions
              </a>
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.privacy}
              >
                Privacy Policy
              </a>
            </div>
            <div
              css={navigationLogoutContainerStyles}
            >
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.logout}
              >
                Logout
              </a>
            </div>
          </div>
        )
      }
    </div>
  );
};

SimplifiedMobileNavigation.propTypes = propTypes;

export default SimplifiedMobileNavigation;
