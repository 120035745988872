import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';

import ConfirmationModal from '../../minted-components-ports/ConfirmationModal';

const modalBodyStyles = css`
    list-style-position: inside;
    padding-left: 0;
`;

const DeleteSiteModal = ({
  close,
  domainIsCustom,
  isOpen,
  isWedding,
  onDeleteSite,
}) => {
  const onSubmit = useCallback(()=> {
    onDeleteSite();
    close();
  }, [
    close,
    onDeleteSite,
  ]);

  return (
    <ConfirmationModal
      isDeleteModal={true}
      onCloseClick={close}
      onSubmit={onSubmit}
      open={isOpen}
    >
      {
        isWedding && (
          <Fragment>
            <div>
              Deleting your wedding website will:
            </div>
            <ul
              css={modalBodyStyles}
            >
              <li>
                Remove all of your content and photos.
              </li>
              {
                !domainIsCustom && (
                  <li>
                    Release your URL so others can use it.
                  </li>
                )
              }
              <li>
                Remove guest lists and all RSVP responses.
              </li>
            </ul>
          </Fragment>
        )
      }
      <div>
        {
          isWedding ?
            'Deleting your wedding website will not remove your account on Minted. Are you sure you want to delete your wedding website?' :
            'Deleting your website will remove all of your content and photos and your card recipients will no longer be able to visit your site. It will not remove your account on Minted. Are you sure you want to delete your website?'
        }
      </div>
    </ConfirmationModal>
  );
};

DeleteSiteModal.propTypes = {
  close: PropTypes.func.isRequired,
  domainIsCustom: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isWedding: PropTypes.bool.isRequired,
  onDeleteSite: PropTypes.func.isRequired,
};

export default DeleteSiteModal;
