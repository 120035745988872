import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useRef,
} from 'react';

import FullNavigation from './FullNavigation';
import MobileNavigation from './MobileNavigation';
import SimplifiedMobileNavigation from './SimplifiedMobileNavigation';
import SimplifiedNavigation from './SimplifiedNavigation';
import navigationLinks from '../../core/dashboard/navigationLinks';
import useElementProp from '../../core/hooks/useElementProp';
import useScrollY from '../../core/hooks/useScrollY';

const {
  breakpoints,
  media,
} = theme;

const propTypes = {
  isMultiSiteDashboard: PropTypes.bool,
  isWedding: PropTypes.bool,
};

const SiteNavigation = ({
  isMultiSiteDashboard = false,
  isWedding = false,
}) => {
  const ref = useRef();

  const {
    isScrolledBelow,
  } = useScrollY(32);

  const elementHeight = (
    useElementProp({
      propName: 'offsetHeight',
      ref,
    })
  );

  return (
    <Fragment>
      <div
        css={
          css`
            height: ${elementHeight}px;
          `
        }
      />

      <div
        css={
          css`
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 11;
          `
        }
        ref={ref}
      >
        <div
          css={
            css`
              display: none;
              ${media.greaterThan(breakpoints.small)(`
                display: block;
              `)}
            `
          }
        >
          {
            isWedding
              ? (
                <FullNavigation
                  isCollapsed={isScrolledBelow}
                  navigationLinks={navigationLinks}
                />
              )
              : (
                <SimplifiedNavigation
                  isMultiSiteDashboard={isMultiSiteDashboard}
                />
              )
          }
        </div>

        <div
          css={
            css`
              ${media.greaterThan(breakpoints.small)(`
                display: none;
              `)}
            `
          }
        >
          {
            isWedding
              ? (
                <MobileNavigation
                  navigationLinks={navigationLinks}
                />
              )
              : (
                <SimplifiedMobileNavigation
                  isMultiSiteDashboard={isMultiSiteDashboard}
                />
              )
          }

        </div>
      </div>
    </Fragment>
  );
};

SiteNavigation.propTypes = propTypes;

export default SiteNavigation;
