import PropTypes from 'prop-types';
import { Component } from 'react';

const propTypes = {
  children: PropTypes.func.isRequired,
};

const contextTypes = {
  router: PropTypes.object.isRequired,
};

const RouterContext = {};

class Consumer extends Component {
  constructor(props, context) {
    super(props);
    this.router = context.router;
  }

  render() {
    const {
      children,
    } = this.props;

    return children({
      router: this.router,
    });
  }
}

Consumer.contextTypes = contextTypes;
Consumer.propTypes = propTypes;

RouterContext.Consumer = Consumer;

export default RouterContext;
