import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

const propTypes = {
  children: PropTypes.func.isRequired,
  defaultState: PropTypes.bool,
};

const OpenManager = ({
  children,
  defaultState = false,
}) => {
  const [
    isOpen,
    setOpen,
  ] = useState(defaultState);

  const close = (
    useCallback(() => {
      setOpen(false);
    }, [])
  );

  const open = (
    useCallback(() => {
      setOpen(true);
    }, [])
  );

  const childProps = {
    close,
    isOpen,
    open,
  };

  return children(childProps);
};

OpenManager.propTypes = propTypes;

export default OpenManager;
