import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import createMemoizer from '../../core/dashboard/createMemoizer';
import { selectCollection } from '../../resources/selectors';
import { fetchSite } from '../../website/actions';

const propTypes = {
  children: PropTypes.func.isRequired,
  site: PropTypes.object,
};

class ViewWeddingWebsite extends Component {
  componentDidMount() {
    const {
      sites,
    } = this.props;

    this.fetchIfNeeded(sites, fetchSite);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      sites,
    } = this.props;

    this.fetchIfNeeded(sites, fetchSite);
  }

  fetchIfNeeded(collection, fetchAction) {
    const {
      dispatch,
    } = this.props;

    if (collection.shouldFetch) {
      dispatch(fetchAction());
    }
  }

  render() {
    const {
      children,
      sites,
    } = this.props;

    const site = (
      (
        sites.items.length === 0
        || sites.shouldFetch
        || sites.isFetching
      )
        ? {}
        : sites.items[0].data
    );

    return children({
      navigateToWeddingWebsite: () => {
        window.open(site.accessKeyLink, '_blank');
      },
    });
  }
}

const memoizeSites = createMemoizer();

const mapStateToProps = ({
  resources,
}) => ({
  sites: (
    memoizeSites(
      selectCollection(resources.sites)
    )
  ),
});

ViewWeddingWebsite.propTypes = propTypes;

export default connect(mapStateToProps)(ViewWeddingWebsite);
