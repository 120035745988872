import { css, Global } from '@emotion/react';
import {
  Icon,
  styleUtils, TextLink, theme,
} from '@minted/minted-components';
import React, { useContext } from 'react';

import MobileNavigationHeader from './MobileNavigationHeader';
import NavigationContext from './NavigationContext';
import NavigationLink from './NavigationLink';
import { navigationLinksPropType } from './utils';
import ViewWeddingWebsite from './ViewWeddingWebsite';
import useOpen from '../../core/hooks/useOpen';
import Track from '../../core/instrumentation';

const {
  rem,
} = styleUtils;

const navigationLinkStyles = (
  css`
    ${theme.typeStyles.bodySmallStyles};
    ${theme.typeStyles.bodyBoldStyles};
    align-items: center;
    border-left: ${rem(3)} solid transparent;
    color: ${theme.colors.gray700};
    cursor: pointer;
    display: flex;
    height: ${rem(48)};
    padding-left: ${theme.spacing.x6};

    :hover {
      background: rgba(230, 230, 230, 0.25);
    }
  `
);

const navigationLinkSelectionStyles = ({
  isActive,
}) => css`
  ${navigationLinkStyles}
  ${theme.typeStyles.bodyBoldStyles}
  ${isActive
  && (
    `border-left-color: ${theme.colors.gray700};`
  )
}
  background: ${isActive
    ? 'rgba(230,230,230,0.5)'
    : 'transparent'
};
`;

const mintedUrlLinkStyles = (
  css`
    ${theme.typeStyles.bodyExtraSmallStyles}
    align-items: center;
    border-left: ${rem(3)} solid transparent;
    color: ${theme.colors.gray700};
    cursor: pointer;
    display: flex;
    height: ${rem(32)};
    padding-left: ${theme.spacing.x6};
    text-decoration: none;

    :hover {
      background: rgba(102, 102, 102, 0.25);
    }
  `
);

const openNavContainerStyles = css`
  background: ${theme.colors.gray100};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9;
`;

const navigationLinkContainerStyles = css`
  border-bottom: ${rem(1)} solid ${theme.colors.gray300};
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationLogoutContainerStyles = css`
  padding-bottom: ${theme.spacing.x3};
  padding-top: ${theme.spacing.x3};
`;

const navigationContainerOverflowScrollStyles = css`
  overflow-y: scroll;
`;

const propTypes = {
  navigationLinks: navigationLinksPropType,
};

const MobileNavigation = ({
  navigationLinks,
}) => {
  const {
    mintedUrls,
  } = useContext(NavigationContext);

  const {
    close,
    isOpen,
    toggle,
  } = useOpen();

  return (
    <div
      css={
        isOpen
        && openNavContainerStyles
      }
    >
      {
        isOpen
        && (
          <Global
            styles={
              css`
                body {
                  overflow: hidden;
                }
              `
            }
          />
        )
      }

      <MobileNavigationHeader
        isOpen={isOpen}
        toggleMenu={toggle}
      />

      {
        isOpen
        && (
          <div
            css={navigationContainerOverflowScrollStyles}
          >
            <div
              css={
                navigationLinkContainerStyles
              }
            >
              {
                navigationLinks
                  .map(({
                    name,
                    routeName,
                  }) => (
                    <NavigationLink
                      key={routeName}
                      routeName={routeName}
                    >
                      {
                        ({
                          isActive,
                          onNavigate,
                        }) => (
                          <div
                            css={
                              navigationLinkSelectionStyles({
                                isActive,
                              })
                            }
                            onClick={
                              () => {
                                Track.emit({
                                  event: 'click_tabs',
                                  value: routeName === 'guests.all' ? 'guests' : routeName,
                                });
                                onNavigate();
                                close();
                              }
                            }
                          >
                            {name}
                          </div>
                        )
                      }
                    </NavigationLink>
                  ))
              }

              <ViewWeddingWebsite>
                {
                  ({
                    navigateToWeddingWebsite,
                  }) => (
                    <div css={navigationLinkStyles}>
                      <TextLink
                        icon={Icon.types['open-in-new']}
                        iconAlignment={TextLink.textLinkIconAlignments.left}
                        onClick={navigateToWeddingWebsite}
                        size="small"
                        text="View Your Website"
                        type={TextLink.textLinkTypes.secondaryAlternate}
                      />
                    </div>
                  )
                }
              </ViewWeddingWebsite>
            </div>

            <div
              css={navigationLinkContainerStyles}
            >
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls['my-account']}
              >
                My Account
              </a>
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.help}
              >
                Website Help
              </a>
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.terms}
              >
                Terms & Conditions
              </a>
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.privacy}
              >
                Privacy Policy
              </a>
            </div>
            <div
              css={navigationLogoutContainerStyles}
            >
              <a
                css={mintedUrlLinkStyles}
                href={mintedUrls.logout}
              >
                Logout
              </a>
            </div>
          </div>
        )
      }
    </div>
  );
};

MobileNavigation.propTypes = propTypes;

export default MobileNavigation;
