import { css } from '@emotion/react';
import { AuthComponent } from '@minted/auth-library';
import { styleUtils, theme } from '@minted/minted-components';
import config from 'config';
import React, { memo, useEffect } from 'react';

const errorStyles = css`
  ${theme.typeStyles.bodySmallStyles}
  color: ${theme.colors.negative800};
  margin-bottom: ${theme.spacing.x2};
  min-height: ${styleUtils.rem(20)};
`;

const headerStyles = css`
  ${theme.typeStyles.displaySmallStyles}
  margin-bottom: ${theme.spacing.base};
  margin-left: ${theme.spacing.x6};
  margin-right: ${theme.spacing.x6};
  text-align: left;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    ${theme.typeStyles.displayLargeStyles}
    margin-bottom: ${theme.spacing.x3};
    text-align: center;
  `)}
`;

const iframeAttrs = {
  height: '100%',
  style: 'min-height: 560px;',
  width: '100%',
};

const loginServiceHost = (
  config.get('loginServiceHost') ||
  `https://login.${window.location.href.split('/')[2].split('.').slice(1).join('.')}`
  // To support multiple domains, if host is not set, it takes it from current URL
);

const SignInPage = ({
  authErrorMessage,
  onSubmitLogin,
  onSubmitSignUp,
}) => {
  useEffect(() => {
    if (config.get('environment') === 'production' && location.protocol === 'http:') {
      location.protocol = 'https:';
    }
  }, []);

  return (
    <div>
      <div css={headerStyles}>
        Welcome back!
      </div>
      {
        authErrorMessage && (
          <div css={errorStyles}>
            {authErrorMessage}
          </div>
        )
      }
      <AuthComponent
        host={loginServiceHost}
        iFrameAttrs={iframeAttrs}
        onSubmitLogin={onSubmitLogin}
        onSubmitSignUp={onSubmitSignUp}
      />
    </div>
  );
};

export default memo(SignInPage);
