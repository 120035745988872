export const setKiboPageType = (pageType) => {
  window.monetateQ = window.monetateQ || [];

  window.monetateQ.push([
    'setPageType',
    pageType,
  ]);

  window.monetateQ.push([
    'trackData',
  ]);
};

/* Sends map of custom variables and page type to Kibo
    customVariables - [{ weddingSiteProductCode: 'ASD-ASD-ASD' }]
    pageType - 'wedding-site-dashboard'
*/
export const setKiboCustomVariables = (customVariables, pageType) => {
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push([
    'setCustomVariables',
    customVariables,
  ]);

  window.monetateQ.push([
    'setPageType',
    pageType,
  ]);

  window.monetateQ.push([
    'trackData',
  ]);
};
