import { css } from '@emotion/react';
import { styleUtils, theme } from '@minted/minted-components';
import React, { memo } from 'react';

import OnboardingArtistAttribution from './OnboardingArtistAttribution';
import SignInPage from './SignInPage';
import MintedWeddingWebsitesLogo from '../../../navigation/dashboard/MintedWeddingWebsitesLogo';

const onSubmit = () => {
  window.location.reload();
};

const backgroundStyles = css`
  background: ${theme.colors.gray100} center top 0px no-repeat url(https://d3t14gfu9ehll4.cloudfront.net/themes/2fd8ddb6-8172-4730-abe8-a16d7f449298/undefined_hjtPbiN.png);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: 100%;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    background-size: 100% auto;
  `)}
`;

const logoStyles = css`
  position: absolute;
  top: 30px;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    left: 25px;
    top: 20px;
  `)}
`;

const mainContentStyles = css`
  padding-bottom: ${styleUtils.rem(100)};
  padding-left: ${theme.spacing.x3};
  padding-right: ${theme.spacing.x3};
  margin-top: ${styleUtils.rem(100)};
  width: 100%;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    margin-top: ${styleUtils.rem(200)};
    max-width: ${styleUtils.rem(650)};
  `)}
`;

const AuthenticationPage = () => (
  <div css={backgroundStyles}>
    <div
      css={logoStyles}
    >
      <MintedWeddingWebsitesLogo />
    </div>
    <div css={mainContentStyles}>
      <SignInPage
        onSubmitLogin={onSubmit}
        onSubmitSignUp={onSubmit}
      />
    </div>
    <OnboardingArtistAttribution
      location='Avondale, AZ'
      name='shoshin studio'
      photo='https://cdn3.minted.com/files/content/designers/200_613730_57ad67762fcd9.jpg'
    />
  </div>
);

export default memo(AuthenticationPage);
