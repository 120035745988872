import { useEffect, useState } from 'react';

export default (scrollDistance) => {
  const [
    isScrolledBelow,
    setScrolledBelow,
  ] = useState(false);

  useEffect(() => {
    const updateScrolledBelow = () => {
      if (scrollDistance === undefined) {
        return;
      }

      setScrolledBelow(window.scrollY > scrollDistance);
    };

    updateScrolledBelow();

    window.addEventListener(
      'scroll',
      updateScrolledBelow,
    );

    return () => {
      window.removeEventListener(
        'scroll',
        updateScrolledBelow,
      );
    };
  }, [
    scrollDistance,
  ]);

  return {
    isScrolledBelow,
  };
};
