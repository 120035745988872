import API from '../index';

export const createSite = (siteData) => (
  API.post(
    'sites',
    {},
    siteData,
  )
);

export const getSites = () => (
  API.get(
    'sites/',
  )
);

export const updateSite = ({
  id, siteData,
}) => (
  API.patch(
    `sites/${id}`,
    {},
    siteData,
  )
);
