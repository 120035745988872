export default (
  [
    {
      name: 'Dashboard',
      routeName: 'dashboard',
    },
    {
      name: 'Settings',
      routeName: 'settings',
    },
    {
      name: 'Designs',
      routeName: 'designs',
    },
    {
      name: 'Pages',
      routeName: 'pages',
    },
    {
      name: 'Guests',
      routeName: 'guests.all',
    },
  ]
    .filter(Boolean)
);
