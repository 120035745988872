import { useCallback, useState } from 'react';

export default (
  initialState = false,
) => {
  const [
    isOpen,
    setOpen,
  ] = useState(initialState);

  const close = (
    useCallback(() => {
      setOpen(false);
    }, [])
  );

  const open = (
    useCallback(() => {
      setOpen(true);
    }, [])
  );

  const toggle = (
    useCallback(() => {
      setOpen(!isOpen);
    }, [
      isOpen,
    ])
  );

  return {
    close,
    isOpen,
    open,
    toggle,
  };
};
