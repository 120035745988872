import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';

export const dropdownContainerStyles = css`
    padding: ${theme.spacing.x2} ${theme.spacing.none};
    textAlign: left;
`;

export const dropdownListStyles = css`
    padding: ${theme.spacing.x2} ${theme.spacing.x6};
    border-left: 3px solid transparent;
    white-space: nowrap;
    &:hover {
      border-left: 3px solid ${theme.colors.gray700};
      cursor: pointer;
    }
`;

export const dropdownListItemStyles = css`
  ${theme.typeStyles.titleExtraSmallStyles};
  text-decoration: none;
  &:hover {
      color: ${theme.colors.gray500};
    }
  svg {
      margin-right: ${theme.spacing.base};
    }
`;
